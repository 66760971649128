import React, { useState } from "react";
import { ethers } from "ethers";
import "./App.css";

function App() {
  const [walletAddress, setWalletAddress] = useState("");
  const [pac, setPac] = useState("");
  const [status, setStatus] = useState("");

  const connectWallet = async () => {
    if (window.ethereum) {
      try {
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        const accounts = await provider.send("eth_requestAccounts", []);
        setWalletAddress(accounts[0]);
        setStatus(`Wallet connected: ${accounts[0]}`);
      } catch (error) {
        console.error("Wallet connection failed:", error);
        setStatus("Wallet connection failed.");
      }
    } else {
      alert("Please install MetaMask to connect your wallet.");
    }
  };

  const submitPac = async () => {
    if (!walletAddress) {
      alert("Please connect your wallet first.");
      return;
    }
    if (!pac) {
      alert("Please enter your Personal Access Code.");
      return;
    }

    try {
      const response = await fetch("https://api.v2.walletchat.fun/oura_register", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          wallet: walletAddress,
          pac: pac,
        }),
      });

      if (response.ok) {
        setStatus("PAC successfully registered!");
        setTimeout(() => {
          window.location.href = "https://leaderboard.intradao.xyz"; // Redirect after 1 second
        }, 1000); // 1000 milliseconds = 1 second
      } else {
        setStatus("Error: Could not register PAC.");
        console.error("Error:", response.statusText);
      }
    } catch (error) {
      setStatus("Network error: Could not register PAC.");
      console.error("Network error:", error);
    }
  };

  return (
    <div className="App">
      <div className="container">
        <h2>Oura Ring Data Registration</h2>
        <p>Enter your Oura Ring Personal Access Code to register your data with your wallet.</p>
        <button onClick={connectWallet}>Connect Wallet</button>
        <input
          className="pac-input"
          type="text"
          value={pac}
          onChange={(e) => setPac(e.target.value)}
          placeholder="Enter Personal Access Code"
        />
        <button onClick={submitPac}>Submit</button>
        <p>{status}</p>
        <p>
          Your Oura Personal Access Code (PAC) gives access to all your Oura Ring data, 
          however no tags are obtained. To see what data is collected, please check our 
          open-source code <a href="https://github.com/Wallet-Chat/walletchatAPI/blob/a4ca0dfb793faede9e4d012682266781eaea9c1c/controllers/chatcontrollers.go#L4704" target="_blank" rel="noopener noreferrer">here</a>.
        </p>
      </div>
    </div>
  );
}

export default App;
